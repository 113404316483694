.sv-action-bar-item__title {
  display: none;
}

/*.sd-navigation__complete-btn {*/
/*  display: none;*/
/*}*/

.sa-table__action-container {
  display: none;
}

.sa-table__row-extension {
  display: none;
}

.sa-table__show-column {
  display: none !important;
}

.sa-tabulator__downloads-bar {
  display: none;
}

@media print {
  /* 隐藏页面上所有内容 */
  body * {
    visibility: hidden;
  }

  /* 只显示 SubmissionPreview 组件的内容 */
  #printableArea, #printableArea * {
    visibility: visible;
  }

  /* 调整 SubmissionPreview 组件的位置 */
  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }
}

